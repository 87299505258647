import React from 'react'
import * as PropTypes from 'prop-types'
import {Button, CloseButton} from '@instructure/ui-buttons'
import showdown from 'showdown'
import {View} from '@instructure/ui-view'
import {Tray} from '@instructure/ui-tray'
import {Flex} from '@instructure/ui-flex'
import {Heading} from "@instructure/ui-heading";
import {Link} from "@instructure/ui-link";
import {IconQuestionLine} from "@instructure/ui-icons";
import {SourceCodeEditor} from "@instructure/ui-source-code-editor";
import ContentItemForm from "./ContentItemForm";


export class MarkdownEditor extends React.Component {

    static propTypes = {
        /**
         * The LTI return URL passed on the launch.
         */
        returnUrl: PropTypes.string,
        /**
         * The data that was passed on the LTI launch to pass back.
         */
        data: PropTypes.string,
        /**
         * The text being edited in markdown.
         */
        text: PropTypes.string
    }

    static defaultProps = {
        text: '',
        data: ''
    }

    // Check accepted types
    // content items...
    state = {
        // The text being edited
        text: '',
        items: '',
        message: ''
    }

    componentDidMount() {
        // Just copy this in so preview works.
        this.setState({text: this.toMarkdown(this.props.text)})
    }

    static showdown = () => {
        return new showdown.Converter({ tables: true })
    }

    toMarkdown(html) {
        const converter = MarkdownEditor.showdown()
        // Showdown doesn't double escape characters that will be escaped by markdown
        const escaped = html.replaceAll(/\\([\\`*_{}[\]()#+\-.!])/g, '\\\\$1')
        return converter.makeMarkdown(escaped)
    }

    onClick = async () => {
        await this.setState({
            items: JSON.stringify(this.makeContentItem(this.state.text))
        })
        this.formRef.current.submit()
    }

    onCancelClick = async () => {
        await this.setState({
            items: JSON.stringify(this.makeContentItem(''))
        })
        this.formRef.current.submit()
    }

    makeContentItem = (text) => {
        const html = this.toHtml(text);
        // Don't return anything if empty
        let item = []
        if (html.length > 0) {
            item.push({
                '@type': 'ContentItem',
                'mediaType': 'text/html',
                'text': html,
                'placementAdvice': {
                    'presentationDocumentTarget': 'embed'
                }
            })
        }
        return {
            '@context': 'http://purl.imsglobal.org/ctx/lti/v1/ContentItem',
            '@graph': item
        }
    }

    toHtml(text) {
        const converter = MarkdownEditor.showdown()
        return converter.makeHtml(text)
    }

    toEmpty = (value) => {
        // This is so that we don't put null values into input elements.
        return value !== null && value !== undefined ? value : ''
    }

    hideTray = () => {
        this.setState({open: false})
    }

    showTray = () => {
        this.setState({open: true});
    }

    renderTray() {
        return <Tray
            label="Preview"
            open={this.state.open}
            onDismiss={this.hideTray}
            size="regular"
            placement="start"
            shouldCloseOnDocumentClick
        >
            <View as="div" padding="medium">
                {this.renderCloseButton()}
                <div dangerouslySetInnerHTML={{__html: this.toHtml(this.state.text)}}/>
            </View>
        </Tray>;
    }

    renderCloseButton() {
        return (
            <Flex>
                <Flex.Item shouldGrow shouldShrink>
                    <Heading>Preview</Heading>
                </Flex.Item>
                <Flex.Item>
                    <CloseButton
                        placement="end"
                        offset="small"
                        screenReaderLabel="Close"
                        onClick={this.hideTray}
                    />
                </Flex.Item>
            </Flex>
        )
    }
    
    handleRef = (ref) => {
        this.formRef = ref
    }

    render() {
        const returnUrl = (this.props.returnUrl)
        const noReturn = !(returnUrl && returnUrl.length > 0)

        return <View as='div' height='100%'>
            <ContentItemForm data={this.props.data} elementRef={this.handleRef} returnUrl={returnUrl}
                             contentItems={this.toEmpty(this.state.items)}/>
            <Flex direction="column" height='100%'>
                <Flex.Item shouldShrink shouldGrow padding='xx-small'>
                    {/* The SourceCodeEditor has bugs to do with scrolling when in controlled mode */}
                    <SourceCodeEditor
                                defaultValue={this.toMarkdown(this.props.text)}
                                label='Markdown source'
                                language='markdown'
                                onChange={(value) => this.setState({text: value})}
                                lineNumbers
                                lineWrapping
                    />
                </Flex.Item>
                <Flex.Item padding='xx-small'>
                    <Flex>
                        <Flex.Item shouldGrow align='start'>
                            <Button onClick={this.showTray} margin='xx-small'>Preview</Button>
                            <Link renderIcon={IconQuestionLine} margin='0 medium' target='_blank'
                                  href='https://wyelearning.com/support/markdown-editor/'>Help</Link>
                        </Flex.Item>
                        <Flex.Item>
                            <Button onClick={this.onCancelClick} margin='xx-small'
                                    interaction={noReturn ? 'disabled' : 'enabled'}>Cancel</Button>
                            <Button color='primary' onClick={this.onClick} margin='xx-small'
                                    interaction={noReturn ? 'disabled' : 'enabled'}>Update</Button>

                        </Flex.Item>
                    </Flex>
                </Flex.Item>
            </Flex>
            {this.renderTray()}
        </View>
    }

}
