import React from 'react'
import PropTypes from "prop-types";

/**
 * Form to submit LTI 1 Content Item request back to the tool 
 */
class ContentItemForm extends React.Component {
    
    static propTypes = {
        returnUrl: PropTypes.string,
        elementRef: PropTypes.func,
        contentItems: PropTypes.object,
        // This is the data value that is passed on on the initial launch that needs to be passed back unchanged.
        data: PropTypes.string,
        message: PropTypes.string
    }
    
    constructor(props) {
        super(props);
        this.formRef = React.createRef()
    }
    
    componentDidMount() {
        this.props.elementRef(this.formRef)
    }


    toEmpty = (value) => {
        // This is so that we don't put null values into input elements.
        return value !== null && value !== undefined ? value : ''
    }

    render() {
        return (
            <form action={this.props.returnUrl} method='POST' ref={this.formRef}>
                <input type='hidden' name='lti_version' value='LTI-1p0'/>
                <input type='hidden' name='lti_message_type' value='ContentItemSelection'/>
                <input type='hidden' name='data' value={this.toEmpty(this.props.data)}/>
                <input type='hidden' name='content_items' value={this.toEmpty(this.props.contentItems)}/>
                <input type='hidden' name='lti_msg' value={this.toEmpty(this.props.message)}/>
            </form>
        )
    }
}

export default ContentItemForm