import React from 'react'

import LtiApplyTheme from './LtiApplyTheme'
import { MarkdownEditor } from './MarkdownEditor'
import LaunchRetriever from './LaunchRetriever'

const settings = {
  'https://localhost:3000': {
    'ltiServer': process.env.REACT_APP_LTI_URL
  },
  'https://markdown.wyelearning.com': {
    'ltiServer': 'https://lti.wyelearning.com'
  }
}

class App extends React.Component {
  state = {
    jwt: null,
    needsToken: false,
    error: null,
    brandConfig: null,
    returnUrl: null,
    highContrast: false,
    data: null
  }

  constructor(props) {
    super(props)
    const origin = window.origin
    if (settings[origin]) {
      this.server = settings[origin].ltiServer
    }
  }

  updateData = async (data) => {
    let selection = data.custom_selection[0]
    // If there isn't any selection is sends through the unexpanded variable
    if (selection === '$com.instructure.Editor.selection') {
      selection = ''
    }

    return this.setState({
      brandConfig: data.custom_brand_config[0],
      highContrast: data.custom_high_contrast[0] === 'true',
      selection: selection,
      returnUrl: data.content_item_return_url[0],
      data: data.data[0]
    })
  }

  render() {
    return (
      <LaunchRetriever ltiServer={this.server} handleJwt={this.updateData}>
        <LtiApplyTheme url={this.state.brandConfig} highContrast={this.state.highContrast}>
          <MarkdownEditor text={this.state.selection} returnUrl={this.state.returnUrl} data={this.state.data}/>
        </LtiApplyTheme>
    </LaunchRetriever>
    )
  }
}

export default App
